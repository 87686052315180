import { HelpCircle } from "@admin-portal-shared-components/icons";
import { Tooltip } from "@admin-portal-shared-components/tooltip";
import { IconButton } from "@hexa-ui/components";
import { Copy as CopyIcon, Mail } from "@hexa-ui/icons";
import { useEnvService, useHasPermission } from "admin-portal-shared-services";
import { StyledAvatar } from "components";
import { AddButton } from "components/Buttons/AddButton";
import { IFeedback, IFeedbacksFunctions } from "components/Feedbacks/Feedbacks";
import { initialLetters } from "helpers/InitialLetters/initialLetters";
import { AnalyticService } from "identity-admin-mfe/modules/account-management/services";
import { IUser } from "interfaces";
import { IAccount } from "interfaces/IAccount";
import { DisassociateButton } from "pages/PocDetails/Components/DisassociationButton/DisassociateButton";
import React, { EffectCallback, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { SegmentService } from "services/segmentService/segmentService";
import { Env } from "../../../../interfaces/IEnv";
import {
  BffService,
  GetRegistrationLinkParams,
} from "../../../../services/bff-service";

import {
  BlockedHeader,
  Container,
  CustomCheckbox,
  CustomH3,
  DeselectAll,
  EmptySpace,
  SelectAll,
  SelectCounter,
  SelectSection,
  UserHeader,
  UserHeaderContainer,
  UserList,
  UserListItem,
  UsersCounter,
} from "./styles";

interface IProps {
  usersList: Array<IUser> | [];
  isLoading: boolean;
  account: IAccount | undefined;
  onCompleteDisassociateAction: EffectCallback;
  feedbackRef: any;
  accountId: string;
  customerAccountId: string;
  country: string;
  pocName: string;
  vendorAccountId: string;
  vendorId: string;
  vendorName: string;
}

export enum EnvironmentEnum {
  DTC = "dtc",
  US = "us",
}

export const getHostEnv = (env: EnvironmentEnum): boolean => {
  return window.location.host.includes(env);
};

export function PocListUsers({
  usersList,
  isLoading,
  account,
  onCompleteDisassociateAction,
  feedbackRef,
  accountId,
  customerAccountId,
  country,
  pocName,
  vendorAccountId,
  vendorId,
  vendorName,
}: IProps): JSX.Element {
  const [usersSelected, setUsersSelected] = React.useState<IUser[]>([]);
  const history = useHistory();
  const canInviteUser = useHasPermission("IdentityFE.CustomerUser.Invite");
  const canCopyRegistrationLink = useHasPermission(
    "IdentityFE.RegistrationLink.Copy"
  );
  const canShowRegistrationLinkButton =
    canCopyRegistrationLink && !getHostEnv(EnvironmentEnum.US);
  const [pendingUsers, setPendingUsers] = React.useState<IUser[]>([]);
  const [activeUsers, setActiveUsers] = React.useState<IUser[]>([]);
  const [blockedUsers, setBlockedUsers] = React.useState<IUser[]>([]);
  const [usersListCount, setUsersListCount] = React.useState(0);
  const canChangeAccountSettings = useHasPermission("IdentityFE.POC.Write");
  const [isLoadingResendInvite, setIsLoadingResendInvite] = useState(false);
  const [isLoadingRegistrationLink, setIsLoadingRegistrationLink] =
    useState(false);
  const envService = useEnvService();
  const isProd = envService.getEnv() === Env.PROD;

  React.useEffect(() => {
    const associatedUsersList = usersList.filter(
      (user) => user.displayName || user.legalName
    );

    const activeUsersFilter = associatedUsersList.filter(
      (user) => user.status === "ACTIVE"
    );
    const pendingUsersFilter = associatedUsersList.filter(
      (user) => user.status === "PENDING"
    );
    const blockedUsersFilter = associatedUsersList.filter(
      (user) => user.status === "BLOCKED"
    );

    setUsersListCount(
      pendingUsersFilter.length +
        blockedUsersFilter.length +
        activeUsersFilter.length
    );

    setActiveUsers(activeUsersFilter);
    setPendingUsers(pendingUsersFilter);
    setBlockedUsers(blockedUsersFilter);
  }, [usersList]);

  const isAValidCustomerInvitationCountry = (country: string) => {
    const validInvitationCountries = ["BR", "US", "ZA"];
    return validInvitationCountries.includes(country);
  };

  const addUser = () => {
    SegmentService.addNewUserStarted();
    const params = `accountId=${encodeURIComponent(
      accountId
    )}&country=${encodeURIComponent(
      country
    )}&customerAccountId=${encodeURIComponent(
      customerAccountId
    )}&pocName=${encodeURIComponent(
      pocName
    )}&vendorAccountId=${encodeURIComponent(
      vendorAccountId
    )}&vendorId=${encodeURIComponent(vendorId)}`;

    history.push({
      pathname: `/invitation/customers?${params}`,
      search: "",
      state: { accountId, country, customerAccountId, pocName },
      callbackUrl: history.location,
    });
  };

  const pushNewFeedback = (feedback: IFeedback) => {
    (feedbackRef.current as IFeedbacksFunctions | undefined)?.pushNewFeedback(
      feedback
    );
  };

  const feedback = (message: string, type: "success" | "error") => {
    const newfeedback: IFeedback = {
      message,
      lifeTime: 3000,
      variant: type,
    };
    pushNewFeedback(newfeedback);
  };

  const handleSelectAll = () => {
    let usersListToSelect: IUser[] = [];
    usersListToSelect = usersListToSelect.concat(
      activeUsers,
      pendingUsers,
      blockedUsers
    );
    const accountSegmentDataParams = {
      ...account,
      canChangeAccountSettings,
      canInviteUser,
      associated_users: usersList.length,
    };
    if (usersSelected.length === 0) {
      SegmentService.allUserSelection({
        ...accountSegmentDataParams,
        selected_users: 0,
      });
      setUsersSelected(usersListToSelect);
    } else {
      SegmentService.allUserDeselection({
        ...accountSegmentDataParams,
        selected_users: usersSelected.length,
      });
      setUsersSelected([]);
    }
  };

  const handleSelectRow = (user: IUser): void => {
    const accountSegmentDataParams = {
      ...account,
      canChangeAccountSettings,
      canInviteUser,
      associated_users: usersList.length,
      selected_users: usersSelected.length,
    };
    const isNotSelected = usersSelected.includes(user);

    if (!isNotSelected) {
      SegmentService.userSelection(accountSegmentDataParams);
      setUsersSelected([...usersSelected, user]);
    }
  };

  const handleDeselectRow = (user: IUser): void => {
    const accountSegmentDataParams = {
      ...account,
      canChangeAccountSettings,
      canInviteUser,
      associated_users: usersList.length,
      selected_users: usersSelected.length,
    };
    const isSelectedId = usersSelected.includes(user);

    if (isSelectedId) {
      SegmentService.userDeselection(accountSegmentDataParams);
      setUsersSelected((users) =>
        users.filter((userSelected) => user.id !== userSelected.id)
      );
    }
  };

  const handleDeselectAll = () => setUsersSelected([]);

  const successFeedback = (quantity: number): void => {
    feedback(
      quantity === 1
        ? "User successfully disassociated."
        : `${quantity} users successfully disassociated.`,
      "success"
    );
  };
  const failsFeedback = (quantity: number): void => {
    feedback(
      quantity === 1
        ? "Operation failed. The user couldn’t be associated."
        : `Operation failed. ${quantity} users couldn’t be associated.`,
      "error"
    );
  };

  const callbackDisassociationResult = (
    results: PromiseSettledResult<any>[]
  ) => {
    const rejectedPromises = results.filter(
      (promise) => promise.status === "rejected"
    );
    const fulfilledPromises = results.filter(
      (promise) => promise.status === "fulfilled"
    );
    if (fulfilledPromises.length > 0) successFeedback(fulfilledPromises.length);
    if (rejectedPromises.length > 0) failsFeedback(rejectedPromises.length);

    onCompleteDisassociateAction();
  };

  const handleCopyRegistrationLink = async (
    params: GetRegistrationLinkParams
  ) => {
    if (!params.inviteId || !params.userId || !params.userCountry) return;
    const { inviteId, userId, userCountry } = params;
    try {
      setIsLoadingRegistrationLink(true);
      const bffService = new BffService();
      const registrationLink = await bffService.getRegistrationLink({
        inviteId,
        userId,
        userCountry,
      });
      if (registrationLink) {
        navigator.clipboard.writeText(registrationLink);
        feedback("Activation URL copied.", "success");
      }
      const userEmail = pendingUsers.find((user) => user.id === userId)?.email;

      AnalyticService.copyCostumerRegistrationLink({
        vendorAccountId,
        accountCountry: country,
        accountName: pocName,
        newUserEmail: userEmail ?? "",
      });
    } catch (error) {
      feedback("Operation failed: activation URL couldn't be copied.", "error");
    } finally {
      setIsLoadingRegistrationLink(false);
    }
  };

  const handleResendInvite = async (user: IUser) => {
    try {
      setIsLoadingResendInvite(true);
      const bffService = new BffService();
      const inviteId = await bffService.ResendInvite({
        userId: user.id,
        country: user.country,
        appName: "b2b",
        invitedUserName: user.displayName ?? user.legalName,
        wholesalerName: vendorName,
        accountsName: [pocName],
        wholesalerId: vendorId,
      });

      setPendingUsers((pendingUsersOld) =>
        pendingUsersOld.map((userPending) =>
          userPending.id === user.id
            ? { ...userPending, inviteId }
            : userPending
        )
      );
      feedback("Invitation sent.", "success");

      AnalyticService.resendCostumerInvitation({
        vendorAccountId,
        accountCountry: country,
        accountName: pocName,
        newUserEmail: user.email ?? "",
      });
    } catch (error) {
      feedback("Operation failed: invitation couldn't be sent.", "error");
    } finally {
      setIsLoadingResendInvite(false);
    }
  };

  const copyRegistrationLinkDisabled = (inviteId?: string): boolean => {
    return !inviteId || !canCopyRegistrationLink;
  };

  const resendInviteDisabled = useCallback(
    (inviteId?: string): boolean => {
      return Boolean(inviteId) || !canInviteUser;
    },
    [canInviteUser]
  );

  const ableToInvite = useCallback(
    (userCountry: string): boolean => {
      const isDtcHost = getHostEnv(EnvironmentEnum.DTC);
      const isUsHost = getHostEnv(EnvironmentEnum.US);
      const isUserFromBRorZA = userCountry === "BR" || userCountry === "ZA";

      if (isDtcHost) return false;
      if (!isProd || isUsHost || (isProd && isUserFromBRorZA)) return true;

      return false;
    },
    [isProd]
  );

  return (
    <Container>
      <UserHeader>
        <CustomH3>
          Associated users
          {usersListCount > 0 && (
            <UsersCounter data-testid="badgeCounter">
              {usersListCount}
            </UsersCounter>
          )}
        </CustomH3>

        <UserHeaderContainer>
          <SelectSection>
            <SelectAll disabled={usersListCount === 0}>
              <CustomCheckbox
                size="small"
                data-checkbox
                data-testid="selectall"
                disabled={usersListCount === 0}
                onClick={handleSelectAll}
                checked={usersSelected.length > 0}
                indeterminate={
                  usersSelected.length < usersListCount &&
                  usersSelected.length > 0
                }
              />
              {usersSelected.length === 0 && <p>Select all</p>}
            </SelectAll>

            {usersSelected.length > 0 && (
              <SelectCounter>{usersSelected.length} Selected /</SelectCounter>
            )}
            {usersSelected.length > 0 && (
              <DeselectAll onClick={handleDeselectAll}>
                Deselect All
              </DeselectAll>
            )}
          </SelectSection>
          {canInviteUser &&
            usersSelected.length === 0 &&
            isAValidCustomerInvitationCountry(country) && (
              <AddButton
                text="Add"
                onClick={addUser}
                data-testid="add-user-btn"
              />
            )}
          <DisassociateButton
            usersSelected={usersSelected}
            usersCount={usersList.length}
            account={account}
            onComplete={callbackDisassociationResult}
          />
        </UserHeaderContainer>
      </UserHeader>

      {usersListCount === 0 && !isLoading && (
        <EmptySpace>No users associated to this account.</EmptySpace>
      )}

      {usersListCount > 0 && !isLoading && activeUsers.length > 0 && (
        <UserList aria-label="active users">
          {activeUsers.map((user) => (
            <UserListItem
              key={user?.id}
              data-testid={`row_${user?.id}`}
              active={usersSelected.includes(user)}
              onClick={() => handleSelectRow(user)}
            >
              <CustomCheckbox
                size="small"
                data-checkbox
                data-testid={user?.id}
                onClick={() => handleDeselectRow(user)}
                checked={usersSelected.includes(user)}
              />
              <StyledAvatar
                data-avatar
                size="48px"
                fontSize="20px"
                style={{ marginLeft: 0 }}
              >
                {initialLetters(user.displayName)}
              </StyledAvatar>

              <div role="contentinfo">
                <h4>{user.displayName}</h4>
                {user?.email && <p>{user.email}</p>}
                {user?.phone && <p>{user.phone}</p>}
              </div>
            </UserListItem>
          ))}
        </UserList>
      )}

      {usersListCount > 0 && !isLoading && pendingUsers.length > 0 && (
        <UserList style={{ marginTop: "24px" }} aria-label="pending users">
          <BlockedHeader
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p>To be onboarded</p>
            <Tooltip
              placement="left"
              text="Users that were already invited to the platform but aren't registered yet."
            >
              <HelpCircle />
            </Tooltip>
          </BlockedHeader>
          {pendingUsers.map((user) => (
            <UserListItem
              key={user?.id}
              data-testid={`row_${user?.id}`}
              active={usersSelected.includes(user)}
              onClick={() => handleSelectRow(user)}
              style={{ justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomCheckbox
                  size="small"
                  data-checkbox
                  data-testid={user?.id}
                  onClick={() => handleDeselectRow(user)}
                  checked={usersSelected.includes(user)}
                />
                <StyledAvatar
                  data-avatar
                  size="48px"
                  fontSize="20px"
                  style={{ marginLeft: 0 }}
                >
                  {initialLetters(user.displayName)}
                </StyledAvatar>

                <div role="contentinfo">
                  <h4>{user.displayName}</h4>
                  {user?.email && <p>{user.email}</p>}
                  {user?.phone && <p>{user.phone}</p>}
                </div>
              </div>
              {ableToInvite(user.country) && (
                <div style={{ display: "flex" }}>
                  {canInviteUser && (
                    <IconButton
                      variant="secondary"
                      size="small"
                      icon={Mail}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                        handleResendInvite(user);
                      }}
                      style={{ marginRight: "16px" }}
                      disabled={resendInviteDisabled(user?.inviteId)}
                      loading={isLoadingResendInvite}
                    />
                  )}
                  {canShowRegistrationLinkButton && (
                    <IconButton
                      variant="secondary"
                      size="small"
                      icon={CopyIcon}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                        handleCopyRegistrationLink({
                          userId: user.id,
                          userCountry: user.country,
                          inviteId: user.inviteId ?? "",
                        });
                      }}
                      disabled={copyRegistrationLinkDisabled(user.inviteId)}
                      loading={isLoadingRegistrationLink}
                    />
                  )}
                </div>
              )}
            </UserListItem>
          ))}
        </UserList>
      )}

      {usersListCount > 0 && !isLoading && blockedUsers.length > 0 && (
        <UserList style={{ marginTop: "24px" }} aria-label="blocked users">
          <BlockedHeader>
            <p>Blocked</p>
          </BlockedHeader>
          {blockedUsers.map((user) => (
            <UserListItem
              key={user?.id}
              data-testid={`row__${user?.id}`}
              active={usersSelected.includes(user)}
              onClick={() => handleSelectRow(user)}
            >
              <CustomCheckbox
                size="small"
                data-checkbox
                data-testid={user?.id}
                onClick={() => handleDeselectRow(user)}
                checked={usersSelected.includes(user)}
              />
              <StyledAvatar
                data-avatar
                size="48px"
                fontSize="20px"
                style={{ marginLeft: 0 }}
              >
                {initialLetters(user.displayName)}
              </StyledAvatar>

              <div role="contentinfo">
                <h4>{user.displayName}</h4>
                {user?.email && <p>{user.email}</p>}
                {user?.phone && <p>{user.phone}</p>}
              </div>
            </UserListItem>
          ))}
        </UserList>
      )}
    </Container>
  );
}
